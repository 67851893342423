import React from 'react'
import Logo from '../assets/images/logo-romulo-seguros.png'
import './components.css'
import { Link } from 'react-scroll'

export default function Footer (): JSX.Element {
  return (
    <footer className='footer'>
      <div className='container-lg d-flex justify-content-between py-3'>
        <div className='logo'>
          <Link to='initial' smooth={true} duration={100}>
            <img
              src={Logo}
              alt='Logo'
              className='img-fluid'
              style={{ maxWidth: '100px' }}
            />
          </Link>
        </div>
        <div className='copyright'>
          <p className='mb-0'>
            © {new Date().getFullYear()} Romulo Seguros. Todos os direitos
            reservados.
          </p>
        </div>
      </div>
    </footer>
  )
}

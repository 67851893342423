import React from 'react'
import { Element} from 'react-scroll'
import Initial from './components/pages/initial'
import Products from './components/pages/products'
import AboutUs from './components/pages/about-us'
import Contact from './components/pages/contact'
import Header from './components/header'
import Footer from './components/footer'

function App () {
  return (
    <div>
      <Header />
      <Element name='initial'>
        <Initial />
      </Element>
      <Element name='products'>
        <Products />
      </Element>
      <Element name='about-us'>
        <AboutUs />
      </Element>
      <Element name='contact'>
        <Contact />
      </Element>
      <Footer />
    </div>
  )
}

export default App;
import styled, { createGlobalStyle } from 'styled-components'

interface LineProps {
  color?: string;
  mtop?: string;
  mbottom?: string;
}

export const Colors = createGlobalStyle`
:root {
  --primary-blue: #0b0464;
  --secondary-blue: #170464;
  --gray-text: #454240;
  --gray-background: #f5f5f5;
}`

export const HomeContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  box-sizing: border-box;
  flex-direction: column;
  color: var(--dark-primary-blue);
  background: var(--gray-background);

  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    width: 70%;
  }

  @media (max-width: 768px) {
    .content {
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (min-width: 1900px) {
    padding-left: 170px;
    padding-right: 170px;
}
`

export const Title = styled.h1`
  font-size: 32px;
  text-align: center;
  margin-bottom: 10px;

  @media (max-width: 768px) {
    font-size: 28px;
  }
`

export const Line = styled.div<LineProps>`
  width: 110px;
  height: 1.5px;
  background-color: ${(props) => props.color || 'white'};
  margin-top: ${(props) => props.mtop || '10px'};
  margin-bottom: ${(props) => props.mbottom || '40px'};
`

export const AboutUsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--secondary-blue);
  padding: 50px;
  box-sizing: border-box;
  flex-direction: column;
  color: white;

  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (min-width: 1900px) {
    padding-left: 170px;
    padding-right: 170px;
  }

`;

export const AboutUsText = styled.p`
  max-width: 85%;
  text-align: center;
  font-size: 16px;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 1.5px;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`

export const ContactContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--gray-background);
  padding: 50px;
  box-sizing: border-box;
  flex-direction: column;
  color: black;

  @media (max-width: 900px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (min-width: 1900px) {
    padding-left: 170px;
    padding-right: 170px;
  }

  a {
    text-decoration: none;
    color: black;
  }
  
  a:hover {
    color: #2828ad;
  }

  .dflex {
    display: flex;
  }

  .content {
    display: flex;
    margin-top: 20px;

    .social-media {
      margin-right: 20px;

      @media (max-width: 768px) {
        width: 100%;
        margin-right: 0px;
        flex-direction: column;
        margin-top: 0px;
      }
    }

    .width60 {
      @media (min-width: 768px) {
        width: 60%;
      }
    }

    @media (min-width: 768px) {
      width: 80%;
    }
    
    @media (max-width: 768px) {
      flex-direction: column;
      margin-top: 0px;
    }
  }

  .content-media {
    display: flex;
    margin-top: 40px;

    &.noMargin {
      @media (min-width: 768px) {
        margin-top: 0px;
      }
    }

    .textMedia {
      display: flex;
      align-items: center;
      margin-left: 15px;
      font-size: 19px;
      font-weight: 500;

      @media (max-width: 768px) {
        font-size: 16px;
      }

      &.email {
        margin-left: 20px;
      }
    }
  }
`;

export const ContactText = styled.p`
  max-width: 85%;
  text-align: center;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  letter-spacing: 1.5px;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`

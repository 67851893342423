import React, { useEffect, useState } from 'react'
import { Navbar, Nav, Dropdown } from 'react-bootstrap'
import { Link } from 'react-scroll'
import Logo from '../assets/images/logo-romulo-seguros.png'
import './components.css'

export default function Header (): JSX.Element {
  const [scrolled, setScrolled] = useState<boolean>(false)

  useEffect(() => {
    let ticking = false

    const handleScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(() => {
          const isScrolled = window.scrollY > 50
          if (isScrolled !== scrolled) {
            setScrolled(isScrolled)
          }
          ticking = false
        })

        ticking = true
      }
    }
  
    document.addEventListener('scroll', handleScroll)
    return () => {
      document.removeEventListener('scroll', handleScroll)
    }
  }, [scrolled])

  return (
    <Navbar expand='lg' fixed='top' className={`header ${scrolled ? 'header-scrolled' : ''}`}>
      <Navbar.Brand as={Link} to='initial' className='ms-5'>
        <img
          src={Logo}
          width={scrolled ? '100' : '170'}
          className='d-inline-block align-top transition-width'
          alt='Logotipo'
        />
      </Navbar.Brand>

      {/* MENU MOBILE */}
      <Dropdown as='nav' className='d-lg-none me-5' drop='start'>
        <Dropdown.Toggle variant='' id='dropdown-basic' className='header-text-color'>
          <span className='navbar-toggler-icon header-toggle-color'></span>
        </Dropdown.Toggle>
        <Dropdown.Menu className='dropdown-menu'>
          <Dropdown.Item as={Link} offset={-70} to='initial' smooth={true} duration={100} className='header-toggle-color'>Início</Dropdown.Item>
          <Dropdown.Item as={Link} offset={-70} to='products' smooth={true} duration={100} className='header-toggle-color'>Seguros</Dropdown.Item>
          <Dropdown.Item as={Link} offset={-70} to='about-us' smooth={true} duration={100} className='header-toggle-color'>Sobre</Dropdown.Item>
          <Dropdown.Item as={Link} offset={-70} to='contact' smooth={true} duration={100} className='header-toggle-color'>Contato</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>

      {/* MENU DESKTOP */}
      <Navbar.Collapse id='basic-navbar-nav' className='justify-content-end me-5 d-none d-lg-block'>
        <Nav>
          <Nav.Link as={Link} offset={-70} to='initial' smooth={true} duration={100} className={`me-2 header-text-color ${scrolled ? 'font-scrolled' : ''}`}>Início</Nav.Link>
          <Nav.Link as={Link} offset={-70} to='products' smooth={true} duration={100} className={`me-2 header-text-color ${scrolled ? 'font-scrolled' : ''}`}>Seguros</Nav.Link>
          <Nav.Link as={Link} offset={-70} to='about-us' smooth={true} duration={100} className={`me-2 header-text-color ${scrolled ? 'font-scrolled' : ''}`}>Sobre</Nav.Link>
          <Nav.Link as={Link} offset={-70} to='contact' smooth={true} duration={100} className={`header-text-color ${scrolled ? 'font-scrolled' : ''}`}>Contato</Nav.Link>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}
import React from 'react'
import { Title, Line } from '../../style'

interface TitleLineProps {
  textTitle: string
  titleClassName?: string
  lineClassName?: string
  lineMarginBottom?: string
  lineColor?: string
}

export function TitleLine ({ textTitle, titleClassName = '', lineClassName = '', lineMarginBottom = '', lineColor = '' }: TitleLineProps): JSX.Element {
  return (
    <>
      <Title className={titleClassName}>{textTitle}</Title>
      <Line className={lineClassName} mbottom={lineMarginBottom} color={lineColor}/>
    </>
  )
}
import React from 'react'
import { AboutUsContainer, AboutUsText } from '../../style'
import { TitleLine } from '../elements/TitleLine'

export default function AboutUs (): JSX.Element {
  return (
    <AboutUsContainer>
      <TitleLine textTitle='SOBRE NÓS'/>
      <AboutUsText>
        Proteger o que é importante para você é a nossa missão. 
        <br></br>
        Com mais de 32 anos de experiência no mercado de seguros, somos especialistas em garantir segurança e tranquilidade. Trabalhamos com as principais seguradoras do Brasil e oferecemos uma ampla gama de serviços, incluindo seguros automotivos, residenciais, empresariais, de vida e responsabilidade civil.
        Nossa equipe altamente qualificada fornece atendimento personalizado, entendendo as necessidades individuais de cada cliente e oferecendo soluções sob medida.
        <br></br>
        Conte conosco para cuidar do que realmente importa. 
        <br></br>
        <b>Seu seguro começa aqui!</b>
      </AboutUsText>
    </AboutUsContainer>
  )
}

export enum DescriptionInsurance {
  AUTO = 'O seguro automóvel oferece proteção financeira abrangente em casos de acidentes, roubo, furto e desastres naturais, cobrindo custos de reparo e danos a terceiros. Além disso, inclui assistência 24 horas para emergências, como guincho e socorro mecânico, proporcionando tranquilidade e segurança. Com diversas opções de pagamento, é um investimento que evita grandes prejuízos financeiros e complicaçãos legais, garantindo que você esteja sempre protegido contra imprevistos.',
  RESIDENCIA = 'O seguro residencial oferece ampla proteção para sua casa e seus pertences, cobrindo danos por incêndios, furtos, desastres naturais e acidentes domésticos. Muitas apólices incluem assistência 24 horas para emergências, como encanadores e eletricistas. Isso proporciona segurança e tranquilidade, sabendo que sua casa está protegida contra imprevistos. Com várias opções de cobertura e pagamento, é um investimento essencial para evitar grandes prejuízos financeiros e garantir a segurança do seu patrimônio.',
  EMPRESARIAL = 'O seguro empresarial protege seu negócio contra danos a propriedades, equipamentos e mercadorias causados por incêndios, roubos e desastres naturais. Inclui cobertura de responsabilidade civil e assistência 24 horas, garantindo a continuidade e segurança da empresa. Com diversas opções de cobertura, é essencial para evitar grandes prejuízos financeiros.',
  VIAGEM = 'O seguro viagem oferece proteção abrangente durante suas viagens, cobrindo despesas médicas, cancelamentos, perdas de bagagem e atrasos de voos. Com assistência 24 horas e diversas opções de cobertura, é um investimento essencial para garantir sua tranquilidade e segurança, evitando grandes prejuízos financeiros e complicações.',
  VIDA = 'O seguro de vida oferece proteção financeira para seus dependentes em caso de falecimento, garantindo suporte econômico em momentos difíceis. Algumas apólices incluem coberturas adicionais, como invalidez permanente e doenças graves. Com diversas opções de cobertura e pagamento, é um investimento essencial para garantir a tranquilidade e a proteção dos seus entes queridos contra imprevistos.',
  DIVERSOS = 'Em busca de outro tipo de seguro? Outras opções são: seguro de responsabilidade civil profissional, seguro de equipamentos agrícolas, seguro de frotas, seguro de eventos, seguro de equipamentos eletrônicos etc.'
}

export enum WhatsAppTextRedirect {
  AUTO = 'https://wa.me/5551998680273?text=Ol%C3%A1%21+Vim+atrav%C3%A9s+do+site+e+gostaria+de+calcular+um+seguro+de+autom%C3%B3vel',
  RESIDENCIA = 'https://wa.me/5551998680273?text=Ol%C3%A1%21+Vim+atrav%C3%A9s+do+site+e+gostaria+de+calcular+um+seguro%C2%A0residencial',
  EMPRESARIAL = 'https://wa.me/5551998680273?text=Ol%C3%A1%21+Vim+atrav%C3%A9s+do+site+e+gostaria+de+calcular+um+seguro%C2%A0empresarial',
  VIAGEM = 'https://wa.me/5551998680273?text=Ol%C3%A1%21+Vim+atrav%C3%A9s+do+site+e+gostaria+de+calcular+um+seguro%C2%A0viagem',
  VIDA = 'https://wa.me/5551998680273?text=Ol%C3%A1%21+Vim+atrav%C3%A9s+do+site+e+gostaria+de+calcular+um+seguro%C2%A0de+vida',
  DIVERSOS = 'https://wa.me/5551998680273?text=Ol%C3%A1%21+Vim+atrav%C3%A9s+do+site+e+gostaria+de+informa%C3%A7%C3%B5es+sobre+um+seguro'
}
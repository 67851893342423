import React, { useState } from 'react'
import { HomeContainer } from '../../style'
import { TitleLine } from '../elements/TitleLine'
import { ProductButton } from '../elements/ProductButton'
import {
  FaCar,
  FaHome,
  FaPlane,
  FaHeartbeat,
  FaGlobe,
  FaBuilding,
  FaWhatsapp,
} from 'react-icons/fa'
import { Modal, Button } from 'react-bootstrap'
import {
  DescriptionInsurance,
  WhatsAppTextRedirect,
} from '../../util/TextsInsuranceEnum'

interface InsuranceProduct {
  description: string
  name: string
  urlWhatsApp: string
  icon: JSX.Element
}

export default function Products (): JSX.Element {
  const [showModal, setShowModal] = useState<boolean>(false)
  const [insuranceDescription, setInsuranceDescription] = useState<string>('')
  const [modalTitle, setModalTitle] = useState<string>('')
  const [urlWhatsApp, setUrlWhatsApp] = useState<string>('')

  const products: InsuranceProduct[] = [
    {
      name: 'Seguro Auto',
      description: DescriptionInsurance.AUTO,
      urlWhatsApp: WhatsAppTextRedirect.AUTO,
      icon: <FaCar size={'80px'} />,
    },
    {
      name: 'Seguro Residencial',
      description: DescriptionInsurance.RESIDENCIA,
      urlWhatsApp: WhatsAppTextRedirect.RESIDENCIA,
      icon: <FaHome size={'80px'} />,
    },
    {
      name: 'Seguro Empresarial',
      description: DescriptionInsurance.EMPRESARIAL,
      urlWhatsApp: WhatsAppTextRedirect.EMPRESARIAL,
      icon: <FaBuilding size={'80px'} />,
    },
    {
      name: 'Seguro Viagem',
      description: DescriptionInsurance.VIAGEM,
      urlWhatsApp: WhatsAppTextRedirect.VIAGEM,
      icon: <FaPlane size={'80px'} />,
    },
    {
      name: 'Seguro de Vida',
      description: DescriptionInsurance.VIDA,
      urlWhatsApp: WhatsAppTextRedirect.VIDA,
      icon: <FaHeartbeat size={'80px'} />,
    },
    {
      name: 'Diversos',
      description: DescriptionInsurance.DIVERSOS,
      urlWhatsApp: WhatsAppTextRedirect.DIVERSOS,
      icon: <FaGlobe size={'80px'} />,
    },
  ]

  const handleClose = (): void => setShowModal(false)

  const redirectWhatsApp = (): void => {
    window.open(urlWhatsApp, '_blank')
  }

  const handleButtonClick = (
    description: string,
    title: string,
    urlWhatsApp: string
  ): void => {
    setModalTitle(title)
    setInsuranceDescription(description)
    setUrlWhatsApp(urlWhatsApp)
    setShowModal(true)
  }

  return (
    <HomeContainer>
      <TitleLine textTitle='FIQUE PROTEGIDO' lineColor='black' lineMarginBottom='15px'/>
      <div className='text-products'>Escolha uma das opções abaixo e entre em contato conosco</div>
      <div className='content'>
        {products.map((product, key) => (
          <ProductButton
            onClick={() =>
              handleButtonClick(
                product.description,
                product.name,
                product.urlWhatsApp
              )
            }
            icon={product.icon}
            name={product.name}
            key={key}
          />
        ))}
      </div>
      <Modal show={showModal} onHide={handleClose} centered>
        <Modal.Header>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{insuranceDescription}</Modal.Body>
        <Modal.Footer className='footer-modal'>
          <Button variant='' onClick={handleClose}>
            Fechar
          </Button>
          <Button variant='success' onClick={redirectWhatsApp}>
            <div className='button-whats-modal'>
              <FaWhatsapp />
              <p className='text-button-modal'>Fale conosco</p>
            </div>
          </Button>
        </Modal.Footer>
      </Modal>
    </HomeContainer>
  )
}

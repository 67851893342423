import React from 'react'

interface ProductButtonProps {
  icon: JSX.Element
  name: string
  onClick?: () => void
}

export const ProductButton: React.FC<ProductButtonProps> = ({ icon, name, onClick }) => {
  return (
    <>
      <div>
        <button className='product-button' onClick={onClick} >{icon}</button>
        <span className='product-button-span'>{name}</span>
      </div>
    </>
  )
}

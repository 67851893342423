import React from 'react'
import { Carousel } from 'react-bootstrap'

const img1 = require('../../assets/carousel/carousel-1.png')
const img2 = require('../../assets/carousel/carousel-2.png')

export default function Initial (): JSX.Element {
  return (
    <Carousel indicators={false}>
      <Carousel.Item className='carousel item-carousel'>
        <img
          src={img1}
          alt='Audi A5'
          className='d-block w-100 img-carousel centered-image'
        />
        <Carousel.Caption>
          <div className='caption-content'>
            <h4>Sua viagem mais tranquila com Seguro Auto</h4>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item className='carousel item-carousel'>
        <img
          src={img2}
          alt='Família'
          className='d-block w-100 img-carousel centered-image'
        />
        <Carousel.Caption>
          <div className='caption-content'>
            <h4>Proteja o que realmente importa</h4>
          </div>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  )
}

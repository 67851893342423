import React from 'react'
import {
  FaFacebookF,
  FaInstagram,
  FaWhatsapp,
  FaEnvelope,
  FaPhone,
} from 'react-icons/fa'
import { ContactContainer } from '../../style'
import './pages.css'
import { TitleLine } from '../elements/TitleLine'

export default function Contact (): JSX.Element {
  return (
    <ContactContainer>
      <TitleLine
        textTitle='CONTE CONOSCO'
        lineMarginBottom='10px'
        lineColor='var(--gray-text)'
      />
      <div className='content'>
        <div className='width60'>
          <div className='social-media'>
            <div className='content-media noMargin'>
              <FaPhone size={49} color='var(--primary-blue)' />
              <span className='textMedia'>(51) 3547-1126</span>
            </div>

            <div className='content-media'>
              <a
                href='https://wa.me/5551998680273'
                target='_blank'
                rel='noreferrer'
                className='dflex'
              >
                <FaWhatsapp size={50} color='var(--primary-blue)' />
                <span className='textMedia'>(51) 9 99868-0273</span>
              </a>
            </div>

            <div className='content-media'>
              <a
                href='mailto:romuloseguroscorretora@gmail.com'
                target='_blank'
                rel='noreferrer'
                className='dflex'
              >
                <FaEnvelope size={45} color='var(--primary-blue)' />
                <span className='textMedia email'>
                  romuloseguroscorretora@gmail.com
                </span>
              </a>
            </div>

            <div className='content-media'>
              <a
                href='https://www.facebook.com/romuloseg'
                target='_blank'
                rel='noreferrer'
                className='dflex'
              >
                <FaFacebookF size={50} color='var(--primary-blue)' />
                <span className='textMedia'>romuloseg</span>
              </a>
            </div>

            <div className='content-media'>
              <a
                href='https://www.instagram.com/romuloseguros'
                target='_blank'
                rel='noreferrer'
                className='dflex'
              >
                <FaInstagram size={50} color='var(--primary-blue)' />
                <span className='textMedia'>romuloseguros</span>
              </a>
            </div>
          </div>
        </div>
        <div className='google-maps-card'>
          <iframe
            src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3467.335034869003!2d-50.58198027899933!3d-29.652050810785244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x9518e460c7c62153%3A0x359903ea2ab27788!2sRomulo%20Seguros!5e0!3m2!1spt-BR!2sbr!4v1716205977782!5m2!1spt-BR!2sbr'
            loading='lazy'
            title='.'
          ></iframe>
          <a
            target='_blank'
            href='https://www.google.com/maps/place/Romulo+Seguros/@-29.6520509,-50.577104,15z/data=!4m2!3m1!1s0x0:0x359903ea2ab27788?sa=X&ved=1t:2428&ictx=111'
          >
            <p className='address-text'>
              Avenida Borges de Medeiros, 2010, sala 10, Centro, Rolante - RS
            </p>
          </a>
        </div>
      </div>
    </ContactContainer>
  )
}
